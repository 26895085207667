/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

// UI lib components
import { Container } from 'react-grid-system';

// Page wrappers
import Layout from '../shared/PageLayout';
import Seo from '../shared/Seo';

// Style
import '../page-styles/404.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function NotFoundPage() {
  /* -------------------------------- CONST --------------------------------- */
  const IS_BROWSER = typeof window !== 'undefined' && window;

  return (
    IS_BROWSER && (
      <Layout>
        <Seo title="404: Not found" />
        <Container className="not-found fluid" fluid>
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Container>
      </Layout>
    )
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NotFoundPage;
